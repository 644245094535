<template>
    <v-card>
      <v-toolbar color="grey lighten-2"  elevation="0" height="60">
        <div class="subtitle-1 font-weight-medium primary--text">1.- Lista de Credenciales Solicitadas</div>
        <v-spacer></v-spacer>
        <div class="text-right subtitle-2 primary--text">TOTAL {{desserts.length}}</div>
      </v-toolbar>
      <v-card-text>
        <v-row v-if="metodoPago.solicitudPasarelaPago">
          <v-col cols="12" v-if="['CREADO','SOLICITADO','EN_PROCESO'].includes(metodoPago.solicitudPasarelaPago.estado)" class="py-3">
            <v-alert color="primary" outlined :value="true" text class="body-2">
              Usted tiene pendiente una solicitud de pago mediante la <b>PASARELA DE PAGOS</b>, para continuar debe 
              <span v-if="metodoPago.solicitudPasarelaPago.metodoPago"> realizar el pago mediante <b>{{metodoPago.solicitudPasarelaPago.metodoPago}}</b> que se muestra a continuacion:</span>
              <span v-else><v-btn color="primary" rounded small @click="getUrlRedireccion(metodoPago.solicitudPasarelaPago.id)">presionar aqui</v-btn> para realizar el pago</span> <span v-if="metodoPago.solicitudPasarelaPago.codigoTransaccion">(código transaccion: {{metodoPago.solicitudPasarelaPago.codigoTransaccion}})</span>
            </v-alert>
          </v-col>
        </v-row>
        <codigo-pago 
          v-if="metodoPago.cpt!== null" 
          :cpt="metodoPago.cpt" 
          :center="true"
        />
        <qr-pago 
          v-if="metodoPago.qr!== null" 
          :qr="metodoPago.qr" 
          :center="true"
        />
        <v-row v-if="credencialPendienteFirma">
        <v-col cols="12" >
        <v-alert color="orange" outlined :value="true" text class="body-2">
          Un operador, realizo cambios a tu solicitud de reposicion/renovacion de credencial por lo tanto debes volver a firmar tu formulario, para continuar con tu tramite
          <v-btn :loading="loading" color="success" rounded small @click="firmarRegistro(credencialPendienteFirma)">presiona aqui</v-btn>
        </v-alert>
        </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class=" mb-5">
              <v-btn
              class="mb-1" 
                color="primary lighten-3"
                small 
                rounded
                @click="nuevaSolicitudCredencial" 
              >
                <v-icon class="mr-2">mdi-card-plus-outline</v-icon>Solicitar Reposición/Renovación
              </v-btn>
              <v-btn @click="getCredenciales" class="ml-2" color="grey" rounded dark small>
                <v-icon >mdi-refresh</v-icon>
              </v-btn>
            </div>
          <nueva-reposicion 
            v-if="dialog" 
            :dialog="dialog" 
            @cancelar="dialog=false"
            @ok="nuevaSolicitudRegistradaExitosamente"
          />
          <iformacion-reposicion
            v-if="dialogInfo"
            :dialog="dialogInfo"
            :idReposicion="idInfo"
            @cerrar="dialogInfo=false"
          />
          <v-data-table
            :headers="headers"
            :items="desserts"
            :calculate-widths="true"
            :hide-default-header="$vuetify.breakpoint.smAndDown"
            hide-default-footer
            :loading="loadingTable"
            loading-text="Cargando Datos"
            mobile-breakpoint="350"

          >
            <template v-slot:item.acciones="{item}">
              <v-icon @click="verInformacion(item.id)">mdi-eye</v-icon>
            </template>
            <template v-slot:item.estado="{item}">
              <v-chip
                :color="item.estado_credencial.nombre==='EMITIDO'?'primary':'blue-grey lighten-3'"
                v-if="item.estado_credencial"
                text-color="white"
                small
              >{{item.estado_credencial.nombre}}</v-chip>
            </template>
            <template v-slot:item.fecha_activacion="{item}">{{formatDateText(item.fecha_activacion)}}</template>
            <template
              v-slot:item.fecha_vencimiento="{item}"
            >{{formatDateText(item.fecha_vencimiento)}}</template>
            
            <template v-slot:item.tipo="{item}">
                <v-chip
                :color="getColorTipoCredencial(item.activo)"
                text-color="white"
                small
              >{{textoTipo(item.tipo_credencial)}}</v-chip>
            </template>
          </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
</template>
<script>
import mixinDateTime from "@/mixins/datetime"
import Mreposicion from "@/mixins/reposicion";
export default {
  mixins: [Mreposicion,mixinDateTime],
  components:{
    'nueva-reposicion':()=>import("@/components/NuevaReposicion"),
    'codigo-pago':()=>import("@/components/Cpt"),
    'qr-pago':()=>import("@/components/QrPago"),
    'iformacion-reposicion':()=>import("@/components/InfoReposicion")
  },
  data() {
    return {
      headers: [
        {
          text: "ACCIONES",
          align: "center",
          value: "acciones",
          sortable: false,
          width: "10px"
        },
        {
          text: "SERIE",
          align: "center",
          value: "serie",
          sortable: false,
          width: "100px"
        },
        {
          text: "ESTADO",
          value: "estado",
          align: 'center',
          sortable: false,
          width: "150px"
        },
        {
          text: "FECHA EMISIÓN",
          value: "fecha_activacion",
          sortable: false,
          width: "150px"
        },
        {
          text: "FECHA VENCIMIENTO",
          value: "fecha_vencimiento",
          sortable: false,
          width: "150px"
        },
        {
          text: "CAUSA EMISIÓN",
          value: "tipo",
          sortable: false,
          width: "150px"
        }
      ],
      desserts: [],
      dialog: false,
      cpt: null,
      metodoPago:{
        solicitudPasarelaPago:null,
        cpt:null,
        qr:null
      },
      idInfo:-1,
      dialogInfo:false,
      loading:false
    };
  },
  async created() {
    if (this.isRegistered) {
      await this.getCredenciales();
    }
  },
  watch: {
    isRegistered(value) {
      if (value) {
        this.getCredenciales();
      }
    }
  },
  computed:{
    credencialNuevo(){
      return this.desserts.find(x=>x.estado_credencial!=='EMITIDO')
    },
    credencialPendienteFirma(){
      return this.desserts.find(x=>x.estado_credencial && x.estado_credencial.nombre==='PENDIENTE FIRMA')
    }
  },
  methods: {
    nuevaSolicitudCredencial(){
      // if(this.credencialNuevo !== undefined){
      //   this.$notify({
      //     group:'foo',
      //     type:'warning',
      //     title:'ADVERTENCIA',
      //     text:'Usted tiene una solicitud de reposicion pendiente'
      //   })
      // }else{
        this.dialog=true
      //}
    },
    nuevaSolicitudRegistradaExitosamente(){
      this.getCredenciales()
      this.dialog=false
    },
    getMetodoPago(id){
      this.$http.get(`${this.$apiUrl}abogado-ciudadano/credenciales/${id}/metodo-pago`)
      .then(response=>{
        this.metodoPago.solicitudPasarelaPago=response.data.data.solicitudPasarelaPago
        this.metodoPago.qr=response.data.data.qr || null
        this.metodoPago.cpt=response.data.data.cpt || null
      }).catch(error=>{
        console.log('error:', error)
      })
    },
    firmarRegistro(credencial){
      this.loading=true
        this.$http.put(`${this.$apiUrl}abogado-ciudadano/credencial/${credencial.id}/solicitar-firma-registro`).then(response=>{
              this.loading=false
              if(response.data.data){
                if(response.data.data.aprobacionCiudadania.linkAprobacion) {
              this.$notify({
                    group:'foo',
                    type:'success',
                    title:'APROBACION DOCUMENTOS',
                    text:'Debe aprobar los documentos de su solicitud con Ciudadanía Digital'
                })
               window.location.href = response.data.data.aprobacionCiudadania.linkAprobacion
            }
              }
          }).catch(error=>{
            console.log(error)
            this.loading=false
        })
    },
    verInformacion(id){
      this.idInfo=id
      this.dialogInfo=true
    },
    getUrlRedireccion(id){
      this.$store.commit('setMessageOverlay', 'Obteniendo informacion de la pasarela de pagos')
      this.$store.commit('setOverlay', true);
       this.$http.get(`${this.$apiUrl}abogado-ciudadano/solicitud-pasarela-pago/${id}/url-redireccion`).then(response=>{
        const {data, error} = response.data
        if(data){
          if(!error && data.estado === 'SOLICITADO' ){
            if(data.codigoTransaccion){
              this.$storage.set('codigo_transaccion',data.codigoTransaccion)
            }
            this.goUrl(data.urlRedireccion)
            return
          }
          if(!error && data.estado === 'EN_PROCESO' ){
            if(data.qr){
              this.metodoPago.qr = null
              const e = data.qr
              this.metodoPago.qr = {
                  id: e.id, 
                  idQr: e.id_qr,
                  codigoQr: e.codigo_qr||null,
                  monto: e.monto,
                  estado: e.estado_codigo.nombre,
                  tipoTramite: "CREDENCIAL",
                  fechaCreacion: e.fecha_creacion,
                  activo: true
              }
              this.metodoPago.solicitudPasarelaPago.metodoPago = 'QR'
            }else{
              this.metodoPago.cpt = null
               const e = data.cpt
              this.metodoPago.cpt = {
                  id: e.id, 
                  codigo: e.codigo_cpt,
                  fechaInicio: e.fecha_inicio,
                  fechaVencimiento: e.fecha_vencimiento,
                  estado: e.estado_codigo.nombre,
                  monto: e.monto
              }
              this.metodoPago.solicitudPasarelaPago.metodoPago = 'PPTE'
            }
          }
        }
        this.$store.commit('setOverlay', false);
       }).catch(error=>{
        this.$store.commit('setOverlay', false);
       })
    },
    goUrl(url){
      window.location.href = url
    }
  }
};
</script>