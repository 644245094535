export default{
    methods:{
        getCredenciales(){
            this.loadingTable=true;
            this.metodoPago={
              solicitudPasarelaPago:null,
              cpt:null,
              qr:null
            }
            this.$http.get(`${this.$apiUrl}abogado-ciudadano/credenciales`)
                .then(response => {
                    if(response.data.data.length>0){
                        this.desserts=response.data.data
                        this.desserts.forEach(element => {
                            if(element.estado_credencial.nombre == 'NUEVO'){
                                  this.getMetodoPago(element.id)
                                  return
                            }
                        });
                    }else{
                        this.$notify({
                            group:'foo',
                            type:'info',
                            title:'IMPORTANTE',
                            text:'Usted no tiene Ninguna Credencial Solicitada'
                        })
                    }
                    this.loadingTable=false;
                })
        },
        getInfoReposicion(id){
            this.$http.get(`${this.$apiUrl}abogado-ciudadano/credenciales/${id}`)
            .then(response=>{
                    this.loading=false
                    this.reposicion=response.data.data.reposicion
                    this.cpt=response.data.data.cpt
                    this.qr=response.data.data.qr
            }).catch(error=>{
                this.loading=false
            })
        },
        textoTipo(value) {
            if (value == 1) {
              return "VENCIMIENTO";
            } else if (value == 2) {
              return "DETERIORO";
            } else {
              return "PERDIDA";
            }
          },
          getColorEstadoCredencial(estado){
            return estado == 'NUEVO'?'grey':estado == 'PAGADO'?'warning':'primary'
          },
          getColorTipoCredencial(activo){
            return activo == null?'grey':activo?'success':'error'
          }
    }
}